import { IndicatorVariants, ProgressIndicatorType } from "ui/primitives/ProgressIndicatorTypes";
import Circular from "./Circular";
import Linear from "./Linear";
import LinearWithIndex from "./LinearWithIndex";
import LinearWithSteps from "./LinearWithSteps";
import ProgressDots from "./ProgressDots";
import s from "./ProgressIndicator.module.scss";
import Tabbed from "./Tabbed";
import { Color } from "../../primitives";
import { Vertical } from "./Vertical";

/**
 * ProgressIndicator
 * Creates different types of progress bars, horizontal, vertical, tabbed, with index, etc
 *
 * @param variant mandatory linear | linearWithSteps | tabbed | progressDots | linearWithIndex | circular | vertical,
 * @param noOfSteps total number of steps
 * @param currentStep current active step
 * @param steps array of objects containing { key: number; label: string; description: string; }[]
 * @param horizontalMargin Horizontal margin for progress bar with index
 * @param color Active color, color of active steps
 * @param backgroundColor InActive color, color of inactive states
 * @param textColor Color of text
 * @param size height of progress bar
 * @param width width of progress bar
 * @param progress used in case of circular progress bar
 */
export const ProgressIndicator = ({
  noOfSteps,
  currentStep,
  variant,
  steps,
  horizontalMargin,
  color = Color.interaction_60,
  backgroundColor = Color.grey_white,
  size,
  width,
  progress,
}: ProgressIndicatorType) => (
  <div className={s.container}>
    {
      {
        [IndicatorVariants.linear]: (
          <Linear
            noOfSteps={noOfSteps}
            currentStep={currentStep}
            color={color}
            backgroundColor={backgroundColor}
            size={size}
          />
        ),
        [IndicatorVariants.linearWithSteps]: (
          <LinearWithSteps
            noOfSteps={noOfSteps}
            currentStep={currentStep}
            steps={steps}
            color={color}
          />
        ),
        [IndicatorVariants.circular]: (
          <Circular
            progress={progress}
            color={color}
            backgroundColor={backgroundColor}
            size={size}
            width={width}
          />
        ),
        [IndicatorVariants.linearWithIndex]: (
          <LinearWithIndex
            noOfSteps={noOfSteps}
            currentStep={currentStep}
            steps={steps}
            horizontalMargin={horizontalMargin}
          />
        ),
        [IndicatorVariants.tabbed]: (
          <Tabbed
            noOfSteps={noOfSteps}
            currentStep={currentStep}
            steps={steps}
          />
        ),
        [IndicatorVariants.progressDots]: (
          <ProgressDots noOfSteps={noOfSteps} currentStep={currentStep} />
        ),
        [IndicatorVariants.vertical]: (
          <Vertical
            noOfSteps={noOfSteps}
            currentStep={currentStep}
            steps={steps}
            backgroundColor={backgroundColor}
            color={color}
            width={width}
            size={size}
          />
        ),
      }[variant]
    }
  </div>
);
