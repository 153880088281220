export enum ActionType {
  BillUpload = 'BILL_UPLOAD',
  TestDrive = 'TEST_DRIVE_BOOKED',
  Moderation = 'DOCUMENT_MODERATION',
  Booked = 'BOOKED',
  TestDriveDone = 'TEST_DRIVE_DONE',
  BillUploaded = 'BILL_UPLOADED',
  Closed = 'CLOSED',
  Default = 'DEFAULT',
  NotInterested = 'NOT_INTERESTED',
  Mismatch = 'MISMATCH',
}

export enum WidgetTypeMapping {
  IndiasChoice = 'INDIAS_CHOICE',
  Slider = 'SLIDER',
  CarListing = 'CAR_LISTING',
  MyBookings = 'MY_BOOKINGS',
  BudgetCars = 'BUDGET_CARS',
  BodyType = 'BODY_TYPE',
  AllBrands = 'ALL_BRANDS',
  CuratedForYou = 'CURATED_FOR_YOU',
  DiscoverCard = 'DISCOVER_CARD',
  LocationBasedPopularCard = 'LOCATION_BASED_POPULAR_CARS',
  WhatsNext = 'WHATS_NEXT',
  Compare = 'COMPARE_THESE_CARS',
  PopularCarVideos = 'POPULAR_CAR_VIDEOS',
  ElectricCars = 'ELECTRIC_CARS',
  Drafted = 'DRAFTED',
  List = 'LIST',
  SimilarlyPricedCars = 'SIMILARLY_PRICED_CARS',
  SimilarSegment = 'SIMILAR_SEGMENT',
  PopularBrands = 'POPULAR_BRANDS',
  BestSellingCars = 'BEST_SELLING_CARS',
  PopularSearch = 'POPULAR_SEARCHES',
  SelfPromotion = 'SELF_PROMOTION',
  MultipleTestDrives = 'MULTIPLE_TEST_DRIVES',
  IndiasTopChoice = 'INDIAS_TOP_CHOICE',
  UserChoice = 'USER_CHOICE',
  NewCarLaunches = 'CHARIOT_CLASSIC_LAUNCHES',
  NewCarBookedWidget = 'USER_BOOKED_INVENTORY',
  CarWars = 'CAR_WARS',
  FutureCar = 'FUTURE_CAR',
  RecommendationModal = 'RECOMMENDATION_MODEL',
  LeadAssitance = 'LEAD_ASSISTANCE_WIDGET',
  CarCompare = 'CAR_COMPARE_WIDGET',
  ContactStrip = 'CALL_DIRECTORY_WIDGET',
  Offers = 'OFFER_BANNER',
  DealerIngress = "DEALERSHIP_WIDGET"
}

export enum BookingStatus {
  Drafted = 'DRAFTED',
  RequestSubmitted = 'REQUEST_SUBMITTED',
}

export type UserDetailsType = {
  name: string;
  email: string;
  city: string;
  pincode: string;
};

export type ListType = {
  leadSource: string;
  dealerName: string;
  id: number;
  year: string;
  modelId: number;
  modelName: string;
  brandName: string;
  variant: string;
  price: string;
  displayPrice: string;
  priority: number;
  highEndPrice: number;
  lowEndPrice: number;
  leadId: number;
  displayModelName: string;
  metaData: {
    bannerImage: string;
    fullImage: string;
    specifications: SpecListType[];
    images?: string[];
    vehicleImages: string[];
    brandLogo?: string;
    useMetaInfo: boolean;
    fetchDealersList: boolean;
  };
  bookingDate?: string;
  testDriveToast?: boolean;
  userAction?: {
    actionType: ActionType;
  };
  title?: string;
  subtitle?: string;
  isBooked: boolean;
  leadType?: string;
  userDetails?: UserDetailsType;
  isBestSelling?: boolean;
  rating?: number;
  make?: string;
  ratingCount: number;
  specText: string;
  status: BookingStatus;
  showBadge: boolean;
  badgeTitle: string;
  bodyType: string;
  variantCount: number;
  testDriveFlowAllowed: boolean;
  societyBookedLead: boolean;
  exteriorImages: string[];
  interiorImages: string[];
  showContactStrip: boolean;
  newCarData: any;
  version: string;
};

export type ListingType = {
  list: ListType[];
  isNotified: boolean;
  userCount: number;
  userDetails?: UserDetailsType;
  question?: string;
  questionId?: number;
};

export type SpecListType = {
  title: string;
  subtitle: string;
  image: string;
  formTitle: string;
};

export type SpecificationsType = {
  variantCount: number;
  list: SpecListType[];
};

export type OfferBannerType = {
  modelId: number;
  image: string;
};

export type WidgetType = {
  widgetType: WidgetTypeMapping;
  payload: any;
  bgColor?: string;
  logo?: string;
  title?: string;
  banners?: OfferBannerType[];
};

export type HomePageType = {
  widgets: [];
  popupData: {
    payload: any;
  };
  userDetails: UserDetailsType;
};

export type CuratedListType = {
  title: string;
  list: ListType[];
}[];

export type BrandsType = {
  title: string;
  brandId: number;
  image: string;
};

export type BookingType = {
  displayModelName: string;
  displayPrice: string;
  inventoryId: number;
  leadId: number;
  leadType: number;
  image: string;
  modelId: number;
  highEndPrice: number;
  lowEndPrice: number;
  bodyType: string;
};

export type ConfirmationDetailsType = {
  bookings: BookingType[];
  widgets: {
    widgetType: WidgetTypeMapping;
    payload?: any;
    title?: string;
  }[];
  userDetails: UserDetailsType;
};

export type CarCompareType = {
  image: string;
  displayModelName: string;
  displayPrice: string;
  modelId: number;
  variantId: number;
};

export type ListDataType = {
  widgetType: WidgetTypeMapping;
  payload?: any;
  data?: ListType;
};

export type ListingPageType = {
  list: ListDataType[];
  userDetails: UserDetailsType;
  error?: any;
};

export type SearchResultType = {
  image: string;
  modelId: number;
  title: string;
  id: number;
};

export type BestSellingCarsType = {
  title: string;
  image: string;
  id: number;
  modelId: number;
};

export type PopularBrandType = {
  title: string;
  image: string;
  brandId: number;
};

export type SearchWidgetsType = {
  widgetType: WidgetTypeMapping;
  list: PopularBrandType[] | BestSellingCarsType[] | SearchResultType[];
  title: string;
};

export type SearchType = {
  searchResults: SearchResultType[];
  widgetList: SearchWidgetsType[];
};

export type QuizOption = {
  optionId: number;
  optionTitle: string;
};

export type CarFinderQuizType = {
  questionId: number;
  questionTitle: string;
  options: QuizOption[];
  totalQuestion: number;
  currentQuestion: number;
  result: ListType[];
  defaultResults: ListType[];
  userDetails: UserDetailsType;
};

export type BillUploadData = {
  widgets: WidgetType[];
  billData: {
    status: string;
    title: string;
    subtitle: string;
    brandId: number;
    modelId: number;
    inventoryId: number;
    deliveryDate: string;
    modelName: string;
    brandName: string;
    image: string;
  }[];
  userDetails: UserDetailsType;
};

export type ContactType = {
  clientId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export type ContactCars = {
  count: number;
  list: CarDetails[];
  syncStatus?: boolean;
  isContact?: boolean;
};

export type CarDetails = {
  brandId: number;
  brandName: string;
  modelId: number;
  modelName: string;
  brandLogo: string;
  modelImage: string;
  minPrice: number;
  maxPrice: number;
  displayModelName: string;
  owners: ContactCars;
};
