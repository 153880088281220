import * as React from "react";
import { isServer } from "../utils/utils";

export function useComponentInViewport(ref, options = {}) {
  if (isServer()) return false;
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), options), []);

  React.useEffect(() => {
    if (ref && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
