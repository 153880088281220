export enum LocalStorage {
  Profile = "profile",
  SegmentAnonymousId = "ajs_anonymous_id",
  UserCart = "user_cart",
  ParkplusStoreV2Cart = "parkplus_store_v2_user_cart",
  PreSelectedAddress = "pre_selected_address",
  Theme = "theme",
  Ppl2024OnboardingShown = "ppl_2024_onboarding_shown",
  Ppl2024ViewRewardMatchList = "ppl_2024_view_reward_match_list",
  DODUserNotified = "dod_user_notified",
  DODhasVisited = "dod_has_visited",
  SessionTracker = "session_tracker",
  ppl_finale_popup_shown = "ppl_finale_popup_shown",
  CreditLineReduxState = "creditLineReduxState",
  Language = "language",
  UserLocations = "userLocations",
  UserProjectId = "userProjectId",
}

export enum SessionStorage {
  InitialPagePath = "source_initial_page_route",
  InitialPageFullPath = "source_initial_page_full_route",
  MallParkingFirstTimeAnimation = "mall_parking_first_time_animation",
  State = "state",
  City = "city",

  MediaUrl = "mediaUrl",
  ActiveBackBtnClass = "activeBackBtnClass",
  MoengageQueuedEvents = "MoengageQueuedEvents",
  TrackingSessionId = "user_session_id",
  nativeLocStatus = "native_loc_status",
  nativeLocLatKey = "native_loc_lat",
  nativeLocLngKey = "native_loc_lng",
  ActiveModalIdentifiers = "active_modal_identifiers",
  AppRatingEventSent = "app_rating_event_sent",
  CartBottomSheetShown = "cart_bottom_sheet_shown",
  IsNewAddress = "isNewAddress",
  AnimatedOrderIds = "animated_order_ids",
  Flow = "flow",
  NativeContactListStatus = "native_contact_list_status",
  NativeContactListChunkCount = "native_contact_list_chunk_count",
  NativeContactListChunkIdx = "native_contact_list_{{chunk_idx}}",
  NativeContactDataChunkCount = "native_contact_list_chunk_count",
  UserContactList = "user_contact_list",
  DonationLandingPageData = "donation_landing_page",

  cartGiftTime = "cartGiftTime",
  nativePermissionStatus = "native_permission_status",
  NativeContactPermissionModalShown = "Native_contact_permission_modal_shown",
  ioclOrderConfirmationRewardShown = "iocl_oc_reward_shown",
  VoucherSelectedCategory = "voucher_selected_category",
  ScannedTagData = "scanned_tag_data",
  TestDriveScoredModels = "test_drive_score_models",
  NewCarScoreModels = "new_car_score_models",
  FuelFridayPopup = "fuel_friday_popup",
  BBPSHomePageData = "bbps_home_page_data",
  utmSource = "utmSource",
  utmCampaign = "utmCampaign",
  utmContent = "utmContent",
  utmTerm = "utmTerm",
  BBPSSavingDetails = "bbps_saving_details",
}

export enum Cookies {
  VersionName = "version-name",
  OsVersion = "os-version",
  DeviceId = "device-id",
  Lat = "lat",
  Lng = "lng",
  City = "city-name",
  State = "state-name",
  UpiMethods = "upiMethods",
  FastagAdditionalData = "fastagAdditionalData",
  ThirdPartyPartnerId = "thirdPartyPartnerId",
  SegmentAnonymousId = "ajs_anonymous_id",
  TrackingSessionId = "user_session_id",
  RefererSource = "referer_source",
  AppOrigin = "app_origin",
  PrimarySource = "primary_source",
  WebviewPlatform = "webview_platform",
  AuthPhoneNo = "authPhoneNumber",
  InitialPagePath = "source_initial_page_route",
  InitialPageFullPath = "source_initial_page_full_route",
  Pincode = "pincode",
  Theme = "setting-theme",
  AppLaunchCount = "app-launch-count",
  Flow = "flow",
  Platform = "platform",
  VersionCode = "version-code",
  DeviceBrand = "device-brand",
  DeviceName = "device-name",
  DeviceModel = "device-model",
  DeviceRam = "device-ram",
  OsName = "os-name",
  AppsflyerId = "appsflyer-id",
  PackageName = "package-name",
  AccessToken = "access-token",
  RefreshToken = "refresh-token",
  FpUniqueId = "fp-unique-id",
  FpRequestId = "fp-request-id",
  PaddingTop = "padding_top",
  PaddingBottom = "padding_bottom",
}
