import classNames from "classnames";
import React from "react";
import { Typography } from "ui";
import useAutofocus from "ui/hooks/useAutofocus";
import useScrollIntoView from "ui/hooks/useScrollIntoView";
import { Color, TypeScales } from "ui/primitives";

import Alert from "ui/components/newIcons/Alert";
import InfoCircle from "../icons/InfoCircle";
import s from "./Input.module.scss";

export enum RenderType {
  FormInput = "FormInput",
  StandAloneInput = "StandAloneInput",
}

interface InputProps
  extends React.HTMLProps<HTMLInputElement | HTMLTextAreaElement> {
  errorMessage?: string;
  helpMessage?: string;
  inputMode?:
    | "text"
    | "search"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "none";
  autoFocus?: boolean;
  hasError?: boolean;
  hasHelp?: boolean;
  scrollIntoView?: boolean;
  placeholder?: string;
  handleChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  label?: string;
  rightIcon?: any;
  leftIcon?: any;
  errorIcon?: any;
  value?: any;
  withShadow?: boolean;
  isTextArea?: boolean;
  disabled?: boolean;
  renderType?: string;
  inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  fieldsetStyleStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  rootStyle?: React.CSSProperties;
  showCharactersLimit?: boolean;
}

const Input = (props: InputProps) => {
  const {
    disabled,
    label,
    inputRef,
    leftIcon,
    rightIcon,
    scrollIntoView,
    value,
    hasError,
    hasHelp,
    handleChange,
    placeholder,
    withShadow,
    autoFocus,
    isTextArea,
    errorMessage,
    helpMessage,
    errorIcon,
    inputMode,
    showCharactersLimit = false,
    renderType = RenderType.FormInput,
    fieldsetStyleStyle = {},
    rootStyle = {},
    inputStyle = {},
    maxLength,
    ...propsToFwd
  } = props;
  const customRef = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(
    null
  );
  useScrollIntoView({ scrollIntoView: scrollIntoView, ref: customRef });
  useAutofocus({ autoFocus: autoFocus, ref: customRef, currentValue: value });

  return (
    <div className={s.root} style={rootStyle}>
      {
        {
          [RenderType.FormInput]: (
            <div style={fieldsetStyleStyle}>
              {label && (
                <label>
                  <Typography
                    label={label}
                    variant={TypeScales.body_para_m}
                    color={Color.text_label}
                    style={{ marginBottom: "4px" }}
                    className={s.label}
                  />
                </label>
              )}
              <div
                className={classNames(s.input, {
                  [s.has_error]: hasError,
                  [s.has_help]: hasHelp,
                  [s.with_shadow]: withShadow,
                  [s.disabled]: disabled,
                  [s.isTextArea]: isTextArea,
                })}
              >
                {isTextArea ? (
                  <textarea
                    //@ts-ignore
                    ref={(ref) => {
                      customRef.current = inputRef
                        ? //@ts-ignore
                          (inputRef.current = ref)
                        : ref;
                    }}
                    onChange={handleChange}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...propsToFwd}
                    rows={4}
                    maxLength={maxLength}
                  />
                ) : (
                  <>
                    {leftIcon && <div className={s.left_icon}>{leftIcon}</div>}
                    <input
                      //@ts-ignore
                      ref={(ref) => {
                        customRef.current = inputRef
                          ? //@ts-ignore
                            (inputRef.current = ref)
                          : ref;
                      }}
                      onChange={handleChange}
                      value={value}
                      disabled={disabled}
                      placeholder={placeholder}
                      inputMode={inputMode}
                      maxLength={maxLength}
                      style={inputStyle}
                      {...propsToFwd}
                    />

                    {rightIcon && (
                      <div className={s.right_icon}>{rightIcon}</div>
                    )}
                  </>
                )}
              </div>
            </div>
          ),
          [RenderType.StandAloneInput]: (
            <div style={fieldsetStyleStyle} className={s.standAloneInput}>
              <div
                className={classNames(s.sa_input, {
                  [s.has_error]: hasError,
                  [s.with_shadow]: withShadow,
                  [s.disabled]: disabled,
                  [s.has_help]: hasHelp,
                })}
              >
                {leftIcon && <div className={s.left_icon}>{leftIcon}</div>}
                <input
                  //@ts-ignore
                  ref={(ref) => {
                    customRef.current = inputRef
                      ? //@ts-ignore
                        (inputRef.current = ref)
                      : ref;
                  }}
                  onChange={handleChange}
                  value={value}
                  disabled={disabled}
                  placeholder={placeholder}
                  inputMode={inputMode}
                  maxLength={maxLength}
                  {...propsToFwd}
                />

                {rightIcon && <div className={s.right_icon}>{rightIcon}</div>}
              </div>
            </div>
          ),
        }[renderType]
      }

      <div className={s.bottomText}>
        <div className={s.messageText}>
          {hasError && errorMessage && (
            <div className={s.error_msg}>
              <Alert
                variant="linear"
                height={16}
                width={16}
                primaryColor="negative_60"
              />
              <Typography
                style={{ marginLeft: "4px" }}
                label={errorMessage}
                variant="small_para_m"
                color="negative_60"
              />
            </div>
          )}
          {hasHelp && helpMessage && (
            <div className={s.help_msg}>
              <InfoCircle
                variant="linear"
                height={16}
                width={16}
                primaryColor="grey_70"
              />
              <Typography
                style={{ marginLeft: "4px" }}
                label={helpMessage}
                variant="small_para_m"
                color="text_subheading"
              />
            </div>
          )}
        </div>
        {!!showCharactersLimit && maxLength > 0 && (
          <Typography
            variant={TypeScales.small_para_m}
            color={Color.text_label}
            label={`${value?.length ?? 0}/${maxLength}`}
            className={s.maxText}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
