/* eslint-disable react/no-array-index-key */
import React from "react";
import { IndicatorType } from "ui/primitives/ProgressIndicatorTypes";
import CheckCircle1 from "ui/components/newIcons/CheckCircle1";
import Typography from "../../Typography";
import s from "./Tabbed.module.scss";

export const Tabbed = ({
                         noOfSteps,
                         color = "positive_50",
                         currentStep,
                         steps
                       }: IndicatorType) => (
  <div className={s.root}>
    {steps?.map((item, idx) => (
      <div className={s.bar}>
        <div className={s.progress_bar} key={idx}>
          <div
            className={s.loader}
            style={{
              background: `var(--${color})`,
              width: currentStep > idx ? "100%" : "0%"
            }}
          />
        </div>
        <div className={s.text}>
          {currentStep > idx ? (
            <CheckCircle1 primaryColor={color} width={14} height={14} />
          ) : (
            <div
              className={s.circle_num}
              style={
                currentStep === idx
                  ? { outline: `1px solid var(--${color})` }
                  : { background: "var(--grey_20)" }
              }
            >
              <Typography
                label={idx + 1}
                variant="tiny_m"
                color={currentStep === idx ? color : "grey_50"}
              />
            </div>
          )}
          <Typography
            label={item?.label}
            variant="small_m"
            color={currentStep < idx ? "grey_50" : "text_default"}
          />
        </div>
      </div>
    ))}
  </div>
);
