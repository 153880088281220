import { isDataValid } from "core-lib/utils/helpers";
import ip from "icepick";

import {
  UserLocationRequestType
} from "core-lib/types/Location";
import {
  ADD_FAMILY_MEMBER_POPUP_UPDATE,
  ADD_FLAT_POPUP_UPDATE,
  BOOKING_SUCCESS_POPUP_FEEDBACK_UPDATE,
  CREATE_VISITOR_POPUP_UPDATE,
  FEEDBACK_POPUP_UPDATE,
  IMAGE_VIEWER_UPDATE,
  RESET_LAYOUT,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_RESPONSE,
  UPDATE_MAP_LOADING_STATUS,
  UPDATE_VISITOR_POPUP_UPDATE,
  VEHICLE_DETAIL_POPUP_UPDATE,
} from "./types";

export const initialState = ip.freeze({
  bookingSuccess: {
    show: false,
    data: null,
    autoExtend: false,
    extendDuration: null,
  },
  createVisitorPopup: {
    show: false,
    data: {
      type: false,
      getCategoryList: null,
    },
  },
  updateVisitorPopup: {
    show: false,
    data: {
      type: null,
      data: null,
      getCategoryList: null,
    },
  },
  mapStatus: {
    status: null, // started, success, failure
  },
  addFlatPopup: {
    show: false,
    data: null,
  },
  addFamilyMemberPopup: {
    show: false,
    data: null,
  },
  imageViewer: {
    show: false,
    data: null,
  },
  vehicleDetailPopup: {
    show: false,
    data: {
      id: null,
    },
  },
  feedbackPopup: {
    show: false,
    data: {
      type: null,
      bookingId: null,
      rating: null,
    },
  },
  userLocationRequest: {
    requestType: UserLocationRequestType.Default,
    payload: null,
    bottomsheetData:null
  },
  userLocationResponse: {
    data: null,
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case BOOKING_SUCCESS_POPUP_FEEDBACK_UPDATE: {
      if (
        state.bookingSuccess.show === true &&
        isDataValid(state.bookingSuccess.data)
      ) {
        const bookingInstance = ip.thaw(state.bookingSuccess.data);
        if (action.payload.type === "parking") {
          let parkingArr = bookingInstance.parking_experience;
          if (Array.isArray(parkingArr)) {
            parkingArr = ip.push(parkingArr, action.payload);
          }
          bookingInstance.parking_experience = parkingArr;
        } else if (action.payload.type === "booking") {
          let bookingArr = bookingInstance.booking_experience;
          if (Array.isArray(bookingArr)) {
            bookingArr = ip.push(bookingArr, action.payload);
          }
          bookingInstance.booking_experience = bookingArr;
        }
        state = ip.setIn(state, ["bookingSuccess", "data"], bookingInstance);
      }
      return state;
    }

    case CREATE_VISITOR_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(
          state,
          ["createVisitorPopup", "show"],
          action.payload.show
        );
      const { data } = action.payload;
      if (data !== undefined) {
        if (data.type !== undefined)
          state = ip.setIn(
            state,
            ["createVisitorPopup", "data", "type"],
            data.type
          );
        if (data.getCategoryList !== undefined)
          state = ip.setIn(
            state,
            ["createVisitorPopup", "data", "getCategoryList"],
            data.getCategoryList
          );
      }
      return state;
    }

    case UPDATE_VISITOR_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(
          state,
          ["updateVisitorPopup", "show"],
          action.payload.show
        );
      const { data } = action.payload;
      if (data !== undefined) {
        if (data.type !== undefined)
          state = ip.setIn(
            state,
            ["updateVisitorPopup", "data", "type"],
            data.type
          );
        if (data.data !== undefined)
          state = ip.setIn(
            state,
            ["updateVisitorPopup", "data", "data"],
            data.data
          );
        if (data.getCategoryList !== undefined)
          state = ip.setIn(
            state,
            ["updateVisitorPopup", "data", "getCategoryList"],
            data.getCategoryList
          );
      }
      return state;
    }

    case UPDATE_MAP_LOADING_STATUS: {
      if (action.payload.status !== undefined) {
        state = ip.setIn(state, ["mapStatus", "status"], action.payload.status);
      }
      return state;
    }

    case ADD_FLAT_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(state, ["addFlatPopup", "show"], action.payload.show);
      return state;
    }

    case ADD_FAMILY_MEMBER_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(
          state,
          ["addFamilyMemberPopup", "show"],
          action.payload.show
        );
      return state;
    }

    case IMAGE_VIEWER_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(state, ["imageViewer", "show"], action.payload.show);
      if (action.payload.data !== undefined)
        state = ip.setIn(state, ["imageViewer", "data"], action.payload.data);
      return state;
    }

    case VEHICLE_DETAIL_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(
          state,
          ["vehicleDetailPopup", "show"],
          action.payload.show
        );
      const { data } = action.payload;
      if (data !== undefined) {
        if (data.id !== undefined)
          state = ip.setIn(
            state,
            ["vehicleDetailPopup", "data", "id"],
            data.id
          );
      }
      return state;
    }

    case FEEDBACK_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(state, ["feedbackPopup", "show"], action.payload.show);
      const { data } = action.payload;
      if (data !== undefined) {
        if (data.type !== undefined)
          state = ip.setIn(state, ["feedbackPopup", "data", "type"], data.type);
        if (data.bookingId !== undefined)
          state = ip.setIn(
            state,
            ["feedbackPopup", "data", "bookingId"],
            data.bookingId
          );
        if (data.rating !== undefined)
          state = ip.setIn(
            state,
            ["feedbackPopup", "data", "rating"],
            data.rating
          );
      }
      return state;
    }

    case RESET_LAYOUT: {
      state = ip.setIn(state, ["bookingSuccess"], initialState.bookingSuccess);
      state = ip.setIn(
        state,
        ["createVisitorPopup"],
        initialState.createVisitorPopup
      );
      state = ip.setIn(
        state,
        ["updateVisitorPopup"],
        initialState.updateVisitorPopup
      );
      state = ip.setIn(state, ["mapStatus"], initialState.mapStatus);
      state = ip.setIn(state, ["addFlatPopup"], initialState.addFlatPopup);
      state = ip.setIn(
        state,
        ["addFamilyMemberPopup"],
        initialState.addFamilyMemberPopup
      );
      state = ip.setIn(state, ["imageViewer"], initialState.imageViewer);
      state = ip.setIn(
        state,
        ["vehicleDetailPopup"],
        initialState.vehicleDetailPopup
      );
      state = ip.setIn(state, ["feedbackPopup"], initialState.feedbackPopup);
      return state;
    }

    case UPDATE_LOCATION_REQUEST: {
      state = ip.setIn(state, ["userLocationRequest"], action.payload);
      return state;
    }

    case UPDATE_LOCATION_RESPONSE: {
      state = ip.setIn(state, ["userLocationResponse"], action.payload);
      return state;
    }

    default:
      return state;
  }
}
