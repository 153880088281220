import { default as classNames, default as cn } from "classnames";
import React, { CSSProperties } from "react";
import { BackButton, Button, Divider, Typography } from "ui";
import { AppBarTypes } from "ui/primitives/AppBarTypes";
import {
  AppBarVariantType,
  Color,
  ColorType,
  RenderType,
  TypeScales,
} from "../../primitives";
import ArrowLeft from "../icons/ArrowLeft";
import s from "./AppBar.module.scss";

function getTitleVariant(variant: AppBarVariantType) {
  return variant === AppBarTypes.Bold
    ? TypeScales.h2
    : TypeScales.base_big_bold;
}

export type ActionButtonType = {
  type?: "default" | "back";
  icon?: any;
  text?: string;
  onClick?: () => void;
  domId?: string;
};

export type AppBarPropTypes = {
  variant?: AppBarVariantType;
  title?: {
    renderAs?: RenderType;
    label: string;
    onClick?: () => void;
    leftIcon?: any;
    rightIcon?: any;
  };
  subtitle?: {
    renderAs?: RenderType;
    label: string;
    onClick?: () => void;
    rightIcon?: any;
  };
  rightActions?: ActionButtonType[];
  leftActions?: ActionButtonType[];
  backButton?: {
    show: boolean;
    onClick?: () => void;
    domId?: string;
  };
  withDivider?: boolean;
  color?: ColorType;
  subtitleColor?: ColorType;
  bgColor?: ColorType;
  backgroundStyle?: CSSProperties;
  isSticky?: boolean;
  zIndex?: number;
  hasPaddingTop?: boolean;
};

function renderButton(btnProps: ActionButtonType, color: ColorType) {
  const { type, text, icon, onClick, domId } = btnProps;
  if (type === "back") {
    return (
      <BackButton domId={domId} onBack={onClick} noPadding>
        <>
          {icon && <div className={s.actionBtn}>{icon}</div>}
          {text && <span style={{ color }}>{text}</span>}
        </>
      </BackButton>
    );
  }
  if (icon) {
    return (
      <span id={domId} className={s.actionBtn} onClick={onClick}>
        {text && <span style={{ color }}>{text}</span>}
        {icon && icon}
      </span>
    );
  }
  return (
    <Button
      style={{ marginRight: "16px", borderRadius: "4px" }}
      size="Small"
      label={text}
      id={domId}
      onClick={onClick}
      variant="ButtonTertiary"
    />
  );
}

export function AppBar({
  variant = AppBarTypes.Default,
  title,
  subtitle,
  rightActions = [],
  leftActions = [],
  backButton = {
    show: true,
  },
  withDivider = true,
  color = Color.text_default,
  subtitleColor = "text_label",
  bgColor = Color.background_primary,
  backgroundStyle,
  isSticky = false,
  zIndex,
  hasPaddingTop = true,
}: AppBarPropTypes) {
  const style: React.CSSProperties = {
    backgroundColor: `var(--${bgColor})`,
    ...backgroundStyle,
  };
  if (isSticky) {
    style.position = "sticky";
    style.top = 0;
  }
  if (hasPaddingTop) {
    style.paddingTop = "var(--safe-area-top)";
  }
  if (zIndex) style.zIndex = zIndex;

  return (
    <div className={s.root} style={style}>
      <div
        className={classNames(s.main, s[variant], {
          [s.noLeftPadding]:
            variant !== AppBarTypes.Bold &&
            (backButton.show || leftActions.length),
          [s.noRightPadding]:
            variant !== AppBarTypes.Bold && rightActions.length,
        })}
      >
        <div className={s.left}>
          {variant !== AppBarTypes.Bold && (
            <>
              {backButton.show && (
                <BackButton
                  domId={backButton.domId}
                  onBack={backButton.onClick}
                  noPadding
                >
                  <div className={s.actionBtn}>
                    <ArrowLeft primaryColor={color} />
                  </div>
                </BackButton>
              )}
              {leftActions?.map((el, idx) => (
                <div key={`${idx + 1}`}>{renderButton(el, color)}</div>
              ))}
            </>
          )}
        </div>
        <div className={s.middle}>
          <div className={s.top}>
            {title && title.label && (
              <div
                aria-hidden
                onClick={() => {
                  if (title?.onClick) title.onClick();
                }}
                className={s.title}
              >
                {title.leftIcon && (
                  <span className={s.title_left_icon}>{title.leftIcon}</span>
                )}
                <Typography
                  renderAs={title.renderAs}
                  label={title.label}
                  color={color}
                  variant={getTitleVariant(variant)}
                />
                {title.rightIcon && (
                  <span className={s.title_right_icon}>{title.rightIcon}</span>
                )}
              </div>
            )}
            {variant === AppBarTypes.Bold && (
              <div className={s.actionList}>
                {leftActions?.map((el, idx) => (
                  <button
                    className={s.actionBtn}
                    type="button"
                    onClick={el.onClick}
                    key={`btn-${idx + 1}`}
                  >
                    {el.text && <span style={{ color }}>{el.text}</span>}
                    {el.icon && el.icon}
                  </button>
                ))}
              </div>
            )}
          </div>
          {subtitle && subtitle.label && (
            <button
              type="button"
              onClick={subtitle.onClick}
              className={cn("cmn_touchable_area", s.subtitle_btn)}
            >
              <Typography
                label={subtitle.label}
                color={subtitleColor}
                variant={TypeScales.body_m}
                renderAs={subtitle?.renderAs}
              />
              {subtitle?.rightIcon && subtitle?.rightIcon}
            </button>
          )}
        </div>
        <div className={s.right}>
          {rightActions?.map((el, idx) => (
            <div key={`btn-${idx + 1}`}>{renderButton(el, color)}</div>
          ))}
        </div>
      </div>
      {withDivider && <Divider />}
    </div>
  );
}
