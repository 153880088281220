import { apiConfig } from "core-lib/config/config";
import {
    TRACK_EVENT,
    TRACK_NATIVE_APP_EVENT,
} from "core-lib/constants/nativeBridge";
import { LocalStorage, SessionStorage } from "core-lib/constants/storage";
import { postMethod } from "core-lib/utils/apiV2";
import { getDefaultAttributes } from "core-lib/utils/events";
import {
    isDataValid,
    isObject,
    removeBadKeysFromObject,
} from "core-lib/utils/helpers";
import { passMessageToNative } from "core-lib/utils/nativeBridge";
import { requiredFieldValidation } from "core-lib/utils/validation";
import dayjs from "dayjs";
import { EventTargets } from "../constants/events";
import { isParkPlusWebView } from "./source";
import { getLocalProfile } from "./tokenStorage";
import {
    generateRequestId,
    isNativeEventTrackingAvailable,
    isStringValid,
} from "./utils";

function addNativeAppAndOsVersion(obj) {
  const newObj = { ...obj };
  const { nativeAppVersion, nativeOSVersion } = global.sessionStorage;
  if (requiredFieldValidation(nativeAppVersion).isValid)
    newObj.app_version = nativeAppVersion;
  if (requiredFieldValidation(nativeAppVersion).isValid)
    newObj.os_version = nativeOSVersion;
  return newObj;
}

export function trackAppsFlyerEvent({ eventName, attributes }) {
  if (isParkPlusWebView()) {
    const filteredObj = removeBadKeysFromObject(attributes);
    let attr = filteredObj ? { ...filteredObj } : {};
    attr = addNativeAppAndOsVersion(attr);
    delete attr.event;
    const profile = getLocalProfile();
    if (isObject(profile)) {
      if (isDataValid(profile.id)) attr.user_id = profile.id;
      if (isDataValid(profile.phone_number))
        attr.mobile_number = profile.phone_number;
    }
    attr.timestamp = dayjs().format("YYYY-MM-DD, dddd, hh:mm A");
    passMessageToNative(TRACK_NATIVE_APP_EVENT, eventName, attr);
  }
}

export async function createClickHouseEvent(obj) {
  const filteredObj = removeBadKeysFromObject(obj);
  let setObj = { ...filteredObj };
  setObj = addNativeAppAndOsVersion(setObj);
  const profile = getLocalProfile();
  if (isObject(profile)) {
    if (isDataValid(profile.id)) {
      setObj.user_id = profile.id;
    }
    if (isDataValid(profile.phone_number)) {
      setObj.mobile_number = profile.phone_number;
    }
  }
  let url = process.env.CLICK_HOUSE_URL;
  if (isDataValid(url)) {
    if (setObj?.table_name === "dealer") {
      const url = `${apiConfig("seoService")}/api/v1/clickhouse-validator`;
      const { response, error } = await postMethod({
        endPoint: url,
        body: { ...setObj },
      });
      if (error) {
        console.log(`Failed to push event ${setObj.event}`, error);
      }
    } else {
      const img = new Image();
      url += "?";
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(setObj)) {
        url += `${key}=${value}&`;
      }
      img.src = url;
    }
  }
}

export function createMoengageEvent(obj) {
  if (!!global?.Moengage?.initialised) {
    const reqKey = generateRequestId();
    addEventsToSessionStorage(obj, reqKey);

    const filteredObj = removeBadKeysFromObject(obj);
    let attr = { ...filteredObj };
    attr = addNativeAppAndOsVersion(attr);
    delete attr.event;
    const profile = getLocalProfile();
    if (isObject(profile)) {
      if (isDataValid(profile.id)) attr.user_id = profile.id;
      if (isDataValid(profile.phone_number))
        attr.mobile_number = profile.phone_number;
    }
    attr.timestamp = dayjs().format("YYYY-MM-DD, dddd, hh:mm A");
    global.Moengage.track_event(obj.event, attr).then(() => {
      const eventsObj = JSON.parse(
        sessionStorage.getItem(SessionStorage.MoengageQueuedEvents)
      );
      if (eventsObj?.[reqKey]) {
        delete eventsObj[reqKey];
        sessionStorage.setItem(
          SessionStorage.MoengageQueuedEvents,
          JSON.stringify(eventsObj)
        );
      }
    });
  }
}

export function trackEMSEvent({ eventName, attributes }) {
  if (!isParkPlusWebView()) {
    const filteredObj = removeBadKeysFromObject(attributes);
    let attr = filteredObj ? { ...filteredObj } : {};
    attr = addNativeAppAndOsVersion(attr);
    delete attr.event;
    const profile = getLocalProfile();
    if (isObject(profile)) {
      if (isDataValid(profile.id)) attr.user_id = profile.id;
      if (isDataValid(profile.phone_number))
        attr.mobile_number = profile.phone_number;
    }
    const sessionTracker: any = localStorage?.getItem(
      LocalStorage.SessionTracker
    )
      ? JSON.parse(localStorage.getItem(LocalStorage.SessionTracker))
      : {};

    const body = {
      data: [
        {
          event_attributes: attr,
          event_name: eventName,
          event_created_time: Date.now().toString(),
        },
      ],
    };

    const url = `${apiConfig("clickStream")}/api/v1/event`;
    postMethod({
      endPoint: url,
      body,
      headers: {
        "session-id": sessionTracker.session_id,
        "session-start-time": sessionTracker.created_at?.toString(),
        "anonymous-id": localStorage.getItem(LocalStorage.SegmentAnonymousId),
      },
    });
  }
}

export function trackEventFunction(
  name,
  dataObj,
  target = [EventTargets.Moengage, EventTargets.Clickhouse, EventTargets.Ems]
) {
  let cmnAttr = isObject(dataObj) ? JSON.parse(JSON.stringify(dataObj)) : {};
  cmnAttr.event = name;
  const defaultAttr = getDefaultAttributes();
  cmnAttr = { ...cmnAttr, ...defaultAttr };
  cmnAttr.user_anonymous_id = localStorage.getItem(
    LocalStorage.SegmentAnonymousId
  );
  cmnAttr.user_session_id = sessionStorage.getItem(
    SessionStorage.TrackingSessionId
  );

  const colorTheme = localStorage.getItem(LocalStorage.Theme);
  if (isStringValid(colorTheme)) {
    cmnAttr.project_name = colorTheme;
  }
  if (isNativeEventTrackingAvailable()) {
    const obj = {
      target,
      eventData: {
        event_name: name,
        ...cmnAttr,
      },
    };
    passMessageToNative(TRACK_EVENT, null, JSON.stringify(obj));
  } else {
    if (target.length === 0 || target.includes(EventTargets.Appsflyer)) {
      trackAppsFlyerEvent({ eventName: name, attributes: dataObj });
      // in case of only appsflyer event
      if (target.length === 1) return;
    }
    createClickHouseEvent({ ...cmnAttr });

    if (!!getLocalProfile()) {
      trackEMSEvent({ eventName: name, attributes: dataObj });
    }

    if (!global?.Moengage?.initialised) {
      const reqKey = generateRequestId();
      addEventsToSessionStorage(cmnAttr, reqKey);
    } else {
      createMoengageEvent(cmnAttr);
    }
  }
}

function addEventsToSessionStorage(cmnAttr, reqKey) {
  if (sessionStorage.getItem(SessionStorage.MoengageQueuedEvents)) {
    const eventsObj = JSON.parse(
      sessionStorage.getItem(SessionStorage.MoengageQueuedEvents)
    );
    eventsObj[reqKey] = cmnAttr;
    sessionStorage.setItem(
      SessionStorage.MoengageQueuedEvents,
      JSON.stringify(eventsObj)
    );
  } else {
    const obj = {};
    obj[reqKey] = cmnAttr;
    sessionStorage.setItem(
      SessionStorage.MoengageQueuedEvents,
      JSON.stringify(obj)
    );
  }
}
