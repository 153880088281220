export const UPDATE_LOCATION = 'testDrive/UPDATE_LOCATION';
export const UPDATE_LANDING_PAGE_DATA = 'testDrive/UPDATE_LANDING_PAGE_DATA';
export const UPDATE_WIDGET = 'testDrive/UPDATE_WIDGET';
export const UPDATE_SEARCH = 'testDrive/UPDATE_SEARCH';
export const UPDATE_LISTING = 'testDrive/UPDATE_LISTING';
export const CLEAR_LANDING_PAGE_DATA = 'testDrive/CLEAR_LANDING_PAGE_DATA';
export const UPDATE_SERVICEABLE_LIST = 'testDrive/UPDATE_SERVICEABLE_LIST';
export const UPDATE_SERVICEABLE_POPUP = 'testDrive/UPDATE_SERVICEABLE_POPUP';
export const UPDATE_FORM_DETAILS = 'testDrive/UPDATE_FORM_DETAILS';
export const UPDATE_DEALER = 'testDrive/UPDATE_DEALER';
export const UPDATE_OEM_SERVICEABLE_POPUP =
  'testDrive/UPDATE_OEM_SERVICEABLE_POPUP';
export const UPDATE_NO_REDIRECT = 'testDrive/UPDATE_REDIRECT';
export const UPDATE_WEB_SUCCESS = 'testDrive/UPDATE_WEB_SUCCESS';
export const UPDATE_LEAD_VERIFICATION = 'testDrive/UPDATE_LEAD_VERIFICATION';
export const UPDATE_CONTACT_DATA = 'testDrive/UPDATE_CONTACT_DATA'
