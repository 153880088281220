import ip from 'icepick';
import { API_STATUS } from 'core-lib/constants/network';

import {
  AUTH_USER,
  RESET_AUTH,
  UPDATE_FUEL,
  UPDATE_ADDRESS,
  LOGOUT_USER,
  LOGIN_POPUP_UPDATE,
} from './types';
import { ReducerType } from '../types/Modal';

const initialState: ReducerType = ip.freeze({
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  // TODO: Temporary Setting profile data to prevent existing use for RequireAuthWithToken case
  profile: {
    date_of_birth: '',
    email_id: '',
    first_name: '',
    full_name: '',
    gender: 1,
    id: '',
    image: '',
    last_name: '',
    middle_name: '',
    phone_number: '',
    photo: { id: 5195, image: '' },
    role: { id: 1, name: 'client', description: 'client', sso_login: false },
    username: '',
  },
  userAddresses: {
    apiStatus: API_STATUS.default,
    addressList: [],
  },
  fuelBalance: {
    apiStatus: API_STATUS.default,
    balance: 0,
  },
  loginPopup: {
    source: '',
    show: false,
    vehicleNumber: {
      show: false,
      data: '',
    },
    autoPopulate: false,
    callbackRoute: null, // Navigate to the  page after log in successful
  },
});

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_USER: {
      if (action.payload.accessToken) {
        state = ip.setIn(state, ['isAuthenticated'], true);
        state = ip.setIn(state, ['accessToken'], action.payload.accessToken);
      }
      if (action.payload.refreshToken)
        state = ip.setIn(state, ['refreshToken'], action.payload.refreshToken);
      if (action.payload.profile)
        state = ip.setIn(state, ['profile'], action.payload.profile);
      return state
    }
    case UPDATE_FUEL:{
      if (action.payload.fuelBalance)
        state = ip.setIn(state, ['fuelBalance'], action.payload.fuelBalance);
      return state;
    }
    case UPDATE_ADDRESS:{
      if (action.payload.userAddresses)
        state = ip.setIn(
          state,
          ['userAddresses'],
          action.payload.userAddresses
        );
      return state
    }
    case LOGIN_POPUP_UPDATE: {
      if (action.payload.show !== undefined)
        state = ip.setIn(state, ['loginPopup', 'show'], action.payload.show);
      if (action.payload.vehicleNumber !== undefined)
        state = ip.setIn(
          state,
          ['loginPopup', 'vehicleNumber'],
          action.payload.vehicleNumber
        );
      if (action.payload.autoPopulate !== undefined)
        state = ip.setIn(
          state,
          ['loginPopup', 'autoPopulate'],
          action.payload.autoPopulate
        );
      if (action.payload.callbackRoute !== undefined)
        state = ip.setIn(
          state,
          ['loginPopup', 'callbackRoute'],
          action.payload.callbackRoute
        );
      if (action.payload.source !== '')
        state = ip.setIn(
          state,
          ['loginPopup', 'source'],
          action.payload.source
        );
      return state;
    }

    case LOGOUT_USER: {
      state = ip.setIn(
        state,
        ['isAuthenticated'],
        initialState.isAuthenticated
      );
      state = ip.setIn(state, ['profile'], initialState.profile);
      state = ip.setIn(state, ['accessToken'], initialState.accessToken);
      state = ip.setIn(state, ['refreshToken'], initialState.refreshToken);
      state = ip.setIn(state, ['loginPopup'], initialState.loginPopup);
      return state;
    }

    case RESET_AUTH: {
      state = ip.setIn(
        state,
        ['isAuthenticated'],
        initialState.isAuthenticated
      );
      state = ip.setIn(state, ['accessToken'], initialState.accessToken);
      state = ip.setIn(state, ['refreshToken'], initialState.refreshToken);
      state = ip.setIn(state, ['profile'], initialState.profile);
      state = ip.setIn(state, ['loginPopup'], initialState.loginPopup);
      return state;
    }

    default:
      return state;
  }
}
