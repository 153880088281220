import { Address, AddressStatusType } from "./Address";

export type LocationRequestResponse = {
  lat: number;
  lon: number;
};

export enum UserLocationRequestType {
  Default = "DEFAULT",
  RequestLatLng = "REQUEST_LAT_LNG",
  RequestGeoAddress = "REQUEST_GEO_ADDRESS",
  RequestLatLngAndGeoAddress = "REQUEST_LAT_LNG_AND_GEO_ADDRESS",
}

export enum UserLocationStatus {
  Success = "SUCCESS",
  Failed = "FAILED",
  Default = "DEFAULT",
}

export type UserLocationData = {
  status: AddressStatusType;
  coords: LocationRequestResponse;
  data: Address;
};

export type ReverseGeoCodeDataResponse = {
  country?: string;
  street?: string;
  subLocality?: string;
  isoCountryCode?: string;
  postalCode?: string;
  name?: string;
  locality?: string;
  administrativeArea?: string;
  subAdministrativeArea?: string;
  thoroughfare?: string;
  subThoroughfare?: string;
};

export enum UserLocationRequirementType {
  LocationMandatory = "LOCATION_MANDATORY",
  LocationCanBeDismissed = "LOCATION_CAN_BE_DISMISSED",
  LocationCanBeSkipped = "LOCATION_CAN_BE_SKIPPED",
}

export enum UserLocationType {
  Current = "current",
  Last = "last",
}

export enum UserGeoAddressType {
  Current = "current",
  Last = "last",
  LatLng = "lat-lon",
}

export type UserLocation = {
  requestType: UserLocationRequestType;
  payload: {
    locationType?: UserLocationType;
    geoAddressType?: UserGeoAddressType;
    givenLatLng?: {
      lat: number;
      lon: number;
    };
    locationRequirementType: UserLocationRequirementType;
  };
  bottomsheetData?: {
    title?: string;
    subtitle?: string;
  };
};
