/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
import { STRAPI_ENDPOINT } from 'core-lib/data/common';
import { getMethod } from 'core-lib/utils/apiV2';
import { isWebView } from 'core-lib/utils/helpers';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'ui';
import { APP_LINK_OTHERS_BANNER } from '../../../data/parkplus_socials';
import s from './DownloadAppBottomBanner.module.scss';

type contentType = {
  primaryContent: string;
  secondaryContent: string;
  buttonText: string;
  url: string;
}[];

type DownloadAppBottomBannerProps = {
  bannerContent: any;
};

const filterContent = (data, pathName) => {
  const arr = data?.filter((item) => item?.url === pathName);
  if (arr?.length <= 0) {
    const content = data?.filter(
      (item) => item?.url === `/${pathName.split('/')[1]}`
    );
    if (content.length <= 0) {
      return data.filter((item) => item?.url === '/');
    }
    return content;
  }
  return arr;
};

export const DownloadAppBottomBanner: React.FunctionComponent<
  DownloadAppBottomBannerProps
> = ({ bannerContent }) => {
  const router = useRouter();
  const { asPath } = router;
  const pathName = router.asPath;
  const [show, setShow] = useState(false);

  const [data, setData] = useState<contentType>(
    filterContent(bannerContent?.data?.attributes?.collections, pathName)
  );

  const handleClick = () => {
    sessionStorage.setItem('show_download_app_banner', 'false');
    setShow(false);
  };

  const getData = async () => {
    const { response: res }: any = await getMethod({
      endPoint: `${STRAPI_ENDPOINT}/api/download-banners?populate=%2A`,
    });
    if (res) {
      setData(filterContent(res?.data?.attributes?.collections, pathName));
    } else {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    const showBanner = sessionStorage.getItem('show_download_app_banner');
    if (!showBanner && !isWebView()) {
      setShow(true);
      !data && getData();
    } else {
      setShow(false);
    }
  }, [asPath, router?.query]);

  return (
    show && (
      <div className={s.banner_wrapper}>
        <div className={s.header}>
          <h4>{data?.[0]?.primaryContent}</h4>
          <p>{data?.[0]?.secondaryContent}</p>
        </div>
        <div className={s.button_div}>
          <Button
            label="Not now"
            variant="PrimaryOutline"
            customStyle={{ border: '1px solid #FFFFFF' }}
            textStyle={{
              color: '#FFFFFF',
              fontSize: '16px',
              padding: '10px 4px',
            }}
            onClick={handleClick}
          />
          <Button
            label={data?.[0]?.buttonText}
            variant="ButtonSecondary"
            textStyle={{
              color: '#1D1D1D',
              fontSize: '16px',
              padding: '10px 4px',
            }}
            onClick={() => router.push(APP_LINK_OTHERS_BANNER)}
          />
        </div>
      </div>
    )
  );
};
