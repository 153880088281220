import { API_STATUS } from 'core-lib/constants/network';
import { AddressStatusType } from 'core-lib/types/Address';
import ip from 'icepick';
import { initDataState } from 'testDrive/constants';
import {
  CLEAR_LANDING_PAGE_DATA,
  UPDATE_DEALER,
  UPDATE_FORM_DETAILS,
  UPDATE_LANDING_PAGE_DATA,
  UPDATE_LISTING,
  UPDATE_LOCATION,
  UPDATE_OEM_SERVICEABLE_POPUP,
  UPDATE_NO_REDIRECT,
  UPDATE_SEARCH,
  UPDATE_SERVICEABLE_LIST,
  UPDATE_SERVICEABLE_POPUP,
  UPDATE_WEB_SUCCESS,
  UPDATE_WIDGET,
  UPDATE_CONTACT_DATA,
  UPDATE_LEAD_VERIFICATION,
} from 'testDrive/store/type';
import { WidgetTypeMapping } from 'testDrive/types';
import { ReducerType } from 'testDrive/types/reducer';

const initialState: ReducerType = ip.freeze({
  address: {
    status: AddressStatusType.Default,
    data: null,
  },
  landingPage: {
    apiStatus: API_STATUS.default,
    data: null,
  },
  searchPage: {
    data: null,
    apiStatus: API_STATUS.default,
    input: null,
  },
  listingPage: {
    apiStatus: API_STATUS.default,
    list: [],
    hasMore: true,
    page: 1,
    userDetails: null,
    filters: "",
  },
  widgets: {
    [WidgetTypeMapping.BodyType]: initDataState.BodyTypeCars,
    [WidgetTypeMapping.BudgetCars]: initDataState.BudgetCars,
    [WidgetTypeMapping.AllBrands]: {
      apiStatus: API_STATUS.default,
      data: [],
    },
    [WidgetTypeMapping.FutureCar]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.CarWars]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.CuratedForYou]: {
      data: null,
      apiStaus: API_STATUS.default,
    },
    [WidgetTypeMapping.DiscoverCard]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.Drafted]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.ElectricCars]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.IndiasTopChoice]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.LocationBasedPopularCard]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
    [WidgetTypeMapping.RecommendationModal]: {
      data: null,
      apiStatus: API_STATUS.default,
    },
  },
  serviceableData: {
    apiStatus: API_STATUS.default,
    list: [],
    oemPayload: null,
  },
  showServiceablePopup: false,
  showOemServiceablePopup: false,
  formDetails: null,
  dealerDetails: null,
  noRedirect: false,
  showWebSuccess: false,
  contactData: {
    data: null,
    modelId : null,
    activeType: null,
  },
  leadVerification: {
    show: false,
    leadIds: [],
  },
});

function testDriveReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LOCATION: {
      state = ip.setIn(state, ['address'], payload);
      return state;
    }
    case UPDATE_LANDING_PAGE_DATA: {
      state = ip.setIn(state, ['landingPage'], payload);
      return state;
    }
    case UPDATE_WIDGET: {
      state = ip.setIn(state, ['widgets'], {
        ...state?.widgets,
        [payload?.widget]: payload.data,
      });
      return state;
    }
    case UPDATE_SEARCH: {
      state = ip.setIn(state, ['searchPage'], payload);
      return state;
    }
    case UPDATE_LISTING: {
      state = ip.setIn(state, ['listingPage'], payload);
      return state;
    }
    case CLEAR_LANDING_PAGE_DATA: {
      state = ip.setIn(state, ['landingPage'], initialState.landingPage);
      state = ip.setIn(state, ['widgets'], initialState.widgets);
      return state;
    }
    case UPDATE_SERVICEABLE_LIST: {
      state = ip.setIn(state, ['serviceableData'], payload);
      return state;
    }
    case UPDATE_SERVICEABLE_POPUP: {
      state = ip.setIn(state, ['showServiceablePopup'], payload);
      return state;
    }
    case UPDATE_FORM_DETAILS: {
      state = { ...state, formDetails: payload };
      return state;
    }
    case UPDATE_DEALER: {
      state = { ...state, dealerDetails: payload };
      return state;
    }
    case UPDATE_OEM_SERVICEABLE_POPUP: {
      state = ip.setIn(state, ['showOemServiceablePopup'], payload);
      return state;
    }
    case UPDATE_NO_REDIRECT: {
      state = ip.setIn(state, ['noRedirect'], payload);
      return state;
    }
    case UPDATE_WEB_SUCCESS: {
      state = ip.setIn(state, ['showWebSuccess'], payload);
      return state;
    }
    case UPDATE_CONTACT_DATA : {
      state = ip.setIn(state, ['contactData'], payload);
      return state;
    }
    case UPDATE_LEAD_VERIFICATION: {
      state = ip.setIn(state, ['leadVerification'], payload);
      return state;
    }
    default: {
      return state;
    }
  }
}

export default testDriveReducer;
